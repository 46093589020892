<template>
  <div class="service">
    <div class="bgContainer">
      <img class="bgImage" src="@/assets/pageBg/service.jpg" />
    </div>

    <div class="content_Container">
      <div class="service_category introduce">
        <h1>服务项目</h1>
        <p>用我们的专业和诚信赢得您的信赖，从PC到移动互联网均有您想要的服务！</p>
        <div class="service_container">
          <template v-for="item in serviceData">
            <div class="service_details">
              <h4>{{item.title}}</h4>
              <img width="80px" height="80px" :src="item.img">
              <span>{{item.content}}</span>
            </div>
          </template>
        </div>
      </div>
      <div class="advantage">
        <div class="advantage_container">
          <h1>这也许是您即将选择我们的理由</h1>
          <p class="subheading">纵然我们已经拥有无数让您选择的理由，但我们期待更多！</p>
          <div class="advantage_list">
            <template v-for="item in advantageList">
              <div class="list_block">
                <div class="block_left">
                  <img :src="item.img">
                </div>
                <div class="block_right">
                  <p class="right_title">
                    {{item.title}}
                    <em></em>
                  </p>
                  <p class="right_text">{{item.content}}</p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="concept">
        <h1>重金打造专业UI设计</h1>
        <!-- <p>最终能让您满意，才是我们的动力。</p> -->
        <p>每个产品细节设计，按钮，都考虑到用户的使用体验，耗费重金打造最完美的用户体验！</p>
        <div class="concept_container">
          <img src="@/assets/service/design.jpg">
          <!-- <div class="container_left">
            <img src="@/assets/service/huabi.png">
          </div>
          <div class="container_right">
            <ol>
              <template v-for="item in conceptList">
                <li>{{item.text}}</li>
              </template>
            </ol>
          </div> -->
        </div>
      </div>
    </div>
    

  </div>
</template>

<script>
export default {
  data() {
    return {
      serviceData: [{
        title: "网站建设",
        img: require("@/assets/service/web.svg"),
        content: "企业网站、购物商城、行业门户、社区论坛、政府/事业单位等网站定制开发！"
      },{
        title: "移动端/APP",
        img: require("@/assets/service/mobile.svg"),
        content: "手机网站、原生APP、API开发、H5单页等移动终端产品定制开发！"
      },{
        title: "微信/小程序",
        img: require("@/assets/service/program.svg"),
        content: "基于微信/小程序模板消息、微信支付等开放组件及接口开发各类微信场景应用！"
      },{
        title: "技术支持",
        img: require("@/assets/service/technology.svg"),
        content: "技术支援、安全防护、模板定制、插件定制、技术方案等技术支持服务"
      }],
      advantageList: [{
        title: "个性定制",
        img: require("@/assets/service/customize.png"),
        content: "您的想法加上我们的专业技术及服务能力产出您想想要的产品,绝非生搬硬套式开发。"
      },{
        title: "开发规范",
        img: require("@/assets/service/norm.png"),
        content: "采用SVN/GIT等工具进行代码版本控制可以溯源代码历史，技术文档更加规范。"
      },{
        title: "极速响应",
        img: require("@/assets/service/response.png"),
        content: "不管项目开发中，还是售后维护阶段；工程师快速响应，紧急问题下班时间照常响应。"
      },{
        title: "安全稳定",
        img: require("@/assets/service/stable.png"),
        content: "实时监测服务器集群运行状态、资源占用率；程序自动进行数据库数据备份。"
      },{
        title: "完整交付",
        img: require("@/assets/service/delivery.png"),
        content: "域名/空间/备案等账号、源代码、技术文档等所有的输出物全部交付给甲方。"
      },{
        title: "完善售后",
        img: require("@/assets/service/afterSale.png"),
        content: "不少于一年售后服务期，包含故障修复、备案、备份、安全防护、问题咨询等。"
      }],
      conceptList: [{
        text: "有责任、有智慧、有温度。"
      },{
        text: "专业、时尚、精准、稳定。"
      },{
        text: "客户利益至上，永续经营；员工快乐成长，服务社会；"
      },{
        text: "注重专业，始终如一；利益至上，永续经营；不断完善，日臻完美；"
      }]
    }
  },
}
</script>

<style scoped>
@import '../../commonScss/style.css';

.content_Container .service_category,
.advantage .advantage_container,
.concept {
  text-align: center;
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
}

.service_category, .advantage, .concept {
  padding: 80px 0;
}

.service_category .service_container {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
}

.service_container .service_details {
  width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.service_details h4 {
  margin-block: 0;
  font-size: 20px;
}

.service_details img {
  width: 80px;
  height: 80px;
  padding: 20px 0;
}

.service_details span {
  font-size: 14px;
  line-height: 20px;
}

.advantage {
  background-color: #303449;
}

.advantage_container h1 {
  margin-top: 0;
  color: white;
}

.advantage_container .subheading {
  color: #cdcdcd;
}

.advantage_container .advantage_list {
  text-align: initial;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.advantage_list .list_block {
  display: flex;
  width: 33.33%;
  padding: 25px;
  box-sizing: border-box;
}

.list_block .block_right {
  margin-left: 20px;
}

.block_right p {
  margin: 0;
}

.block_right .right_title {
  color: #eee;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 13px;
  margin-bottom: 13px;
  display: inline-block;
  position: relative;
}

.right_title em {
  position: absolute;
  height: 2px;
  bottom: 0;
  left: 0;
  width: 50%;
  background: #0595c7;
  transition: all 0.3s;
}

.list_block:hover .block_right .right_title em {
  width: 100%;
}

.block_right .right_text {
  font-size: 13px;
  color: #999;
  line-height: 22px;
  letter-spacing: 1px;
}

.concept h1 {
  margin-top: 0;
}

.concept .concept_container {
  margin-top: 40px;
  /*text-align: initial;
  display: flex;
  justify-content: center;*/
}

.concept_container .container_right {
  margin-left: 150px;
}

.container_right ol {
  margin: 0;
}

.container_right ol li:nth-child(n + 2) {
  margin-top: 20px;
}
</style>